// the loading overlay component isn't based on the Component class or Preact as it is assumed that we're still loading some JS when this becomes available.
// To use, just include `templates/loading_overlay.js` on your page, then call `window.loading_overlay.request_show(STAGE_NUMBER)` to advance the loading stage.

let anime = null;
const bgColor = "#ffffff";
const fgColor = "#1c3841";
const orange1 = '#f47d5a';
const green1 = '#52be96';


export default class LoadingOverlay {
  /**
   * The LoadingOverlay component shows immediately upon page load and shows several stages of loading using css _stage3_animations, animejs, and canvas:
   * 0: white background, small logo in top right, progress bar at top animates from 0% toward 24%.
   * 1: white background, progress bar is hidden (for use beneath login dialogs).
   * 2: white background, small logo in top right, progress bar animates from 24% toward 76%. (for immediately after login is complete)
   * 3: particle background, progress bar jumps to 100%, then big logo animation plays.
   * 4: immediate fade out (for when the user is already logged in and has seen the animation recently)
   * 5: white background, small logo in top right, progress bar appears disabled and an error message is shown.
   *
   * All stages show a muted logout button in lower right.
   */
  constructor() {
    this.element = document.body.querySelector('.loading-overlay');
    this.element.style.display = 'block';
    this.element.classList.add('show')
    this.element.querySelector('.logo').style.display = 'flex';
    this.when_anime_js = import('~/vendor/common.min.js').then((a) => {
      anime = a.anime;
    });

    this._hide_promise = new Promise((resolve) => {
      this._resolve_hide_promise = resolve;
    });
    this._canvas_el = document.getElementById("loading-overlay-canvas");
    this.cW = this._canvas_el.width = window.innerWidth;
    this.cH = this._canvas_el.height = window.innerHeight;

    this._stage2_animation = null;
    this._stage3_animations = [];
    this._stage3_animation = null;
    this.progress_el = this.element.querySelector('.progress');
    this.logo_el = this.element.querySelector('.logo');
    // used to suppress repeated calls to show stages 3-4.
    this.has_been_hidden = false;

    this.request_show(window.loading_overlay_default_stage || 0);
  }

  get canvas_el() {
    if (!this._canvas_el) {
      this._canvas_el = document.createElement('canvas')
      this._canvas_el.id = "loading-overlay-canvas";
      this.element.appendChild(this._canvas_el);
    }
    return this._canvas_el
  }

  get canvas_ctx() {
    if (!this._canvas_ctx) {
      this._canvas_ctx = this.canvas_el.getContext("2d")
      this._resizeCanvas()
    }
    return this._canvas_ctx;
  }

  async request_hide(immediate = false) {
    if (immediate) {
      this.element.style.display = 'none';
      this._resolve_hide_promise();
      return
    }
    return this.request_show(4)
  }


  async request_show(loading_stage = 0, error_msg = null) {
    // suppress repeated calls to 3 or 4.
    if (this.has_been_hidden) {
      if (loading_stage <= 2) {
        this.has_been_hidden = false;
      } else {
        return
      }
    }
    this.element.style.display = 'block';

    if (loading_stage === 0) {
      // stage 0 uses a css animation.
      this.logo_el.style.display = 'flex';
      this.progress_el.classList.add('show');
      this.progress_el.classList.add('stage0');
    } else {
      // make sure the css animation doesn't continue after stage0
      this.progress_el.classList.remove('stage0');
      this.progress_el.style.width = '24%';
    }
    if (loading_stage === 1) {
      this.progress_el.style.display = 'none';
      this.logo_el.classList.add('show');
    }
    if (loading_stage === 2) {
      this.logo_el.classList.add('show');
      if (this._canvas_ctx) {
        try {
          this._canvas_ctx.clearRect(0, 0, this.cW, this.cH);
        } catch (_) {
          // do nothing
        }
      }
      this.progress_el.style.display = 'block';
      window.addEventListener("resize", this._resizeCanvas.bind(this));
      await this.when_anime_js
      this._stage2_animation = anime({
        targets: this.progress_el,
        easing: 'linear',
        duration: anime.random(4200, 7000),
        width: ['24%', '76%']
      });
    } else {
      if (this._stage2_animation) {
        this._stage2_animation.pause();
      }
    }

    // Skip from stage 3 to stage 4 for small devices.
    if (loading_stage === 3 && (window.innerWidth < 768 || window.innerHeight < 500)) {
      loading_stage = 4
    }

    if (loading_stage === 3) {
      // skip progress bar to 100%.
      this.progress_el.style.width = '100%';

      this.logo_el.classList.remove('show');
      this._resizeCanvas();
      this.init_stage3_animation(this.cW, this.cH, this.canvas_ctx);
    }
    if (loading_stage === 4) {
      if (this._stage3_animation) {
        this._stage3_animation.pause()
      }
      this.progress_el.style.width = '100%';
      this.element.classList.add('fade');
      this.element.classList.remove('show');

      this._dnone_timeout_handle = window.setTimeout(()=>{
        this.element.style.display = 'none';
      }, 300);
      // schedule cleanup.
      this.has_been_hidden = true;
    } else {
      this.element.classList.add('show');
    }
    if (loading_stage === 5) {
      this.progress_el.style.display = 'block';
      this.progress_el.style.width = '100%';
      this.progress_el.style.borderTopColor = '#c4c4c4';
      this._error_el = document.createElement('div');
      this._error_el.classList.add('loading-error-message');
      this._error_el.innerHTML = `${error_msg || "Something went wrong."} Please&nbsp;<a href='/' onclick="(!!window.portal_manager ? window.portal_manager.logout() : (!!window.app && !!window.app.auth_manager ? window.app.auth_manager.logout() : document.location = '/?b=' + Math.floor(Math.random() * Math.floor(100000))))" style="color: #afafaf !important;">logout</a>&nbsp;and try again.`
      this.element.appendChild(this._error_el);
    }
  }

  _resizeCanvas() {
    this.cW = this.canvas_el.width = window.innerWidth * devicePixelRatio;
    this.cH = this.canvas_el.height = window.innerHeight * devicePixelRatio;
    this.canvas_ctx.scale(devicePixelRatio, devicePixelRatio);
  }

  destroy() {
    // does some limited cleanup to save resources
    this._stage3_animation = null;
    this._stage3_animations = [];
    this._stage2_animation = null;

    if (this._canvas_ctx) {
      this._canvas_ctx = null;
    }
    if (this._canvas_el) {
      this.element.removeChild(this._error_el);
      this._canvas_el = null;
    }
  }

  init_stage3_animation(cW, cH, ctx) {
    const arrow1 = new Path2D('M56.317,123.629c-13.233,-0 -25.813,-4.092 -36.396,-11.592c-5.958,3.813 -12.446,6.863 -19.358,8.942c15.25,14.071 34.883,21.858 55.754,21.854c45.429,-0 82.392,-36.958 82.392,-82.392l-0,-27.612l16.033,16.212l11.225,-11.1l-36.667,-37.083l-37.083,36.667l11.1,11.229l16.162,-15.984l0,27.671l0.025,0c0,34.842 -28.345,63.188 -63.187,63.188');
    const arrow1_transform = [1, 0, 0, 1, 82, 50];
    const arrow2 = new Path2D('M31.542,147.663c8.541,4.648 18.326,7.291 28.733,7.291c33.254,0 60.217,-26.958 60.217,-60.212c-0,-33.259 -26.963,-60.217 -60.217,-60.217c-33.254,-0 -60.216,26.958 -60.216,60.217c-0,0.025 0.004,0.05 0.004,0.075c0.001,0.925 0.023,1.846 0.066,2.763c0.412,21.552 5.633,41.896 14.868,59.975c22.921,44.867 69.575,75.675 123.321,75.675c76.213,0 138.229,-61.916 138.404,-138.087l0.009,-0l-0,-62.666l16.199,15.9l11.059,-11.267l-37.217,-36.529l-36.529,37.217l11.266,11.058l15.992,-16.291l0,62.578l0.017,-0c-0.179,65.579 -53.579,118.883 -119.2,118.883c-36.779,0 -69.712,-16.75 -91.6,-43.012c-5.935,-7.13 -11.012,-14.967 -15.176,-23.351Zm28.733,-92.984c22.092,0 40.063,17.971 40.063,40.063c-0,22.087 -17.971,40.058 -40.063,40.058c-22.087,-0 -40.058,-17.971 -40.058,-40.058c-0,-22.092 17.971,-40.063 40.058,-40.063Z');
    const arrow2_transform = [1, 0, 0, 1, 0, 0];
    const clip_mask1 = new Path2D();
    clip_mask1.addPath(arrow2, new DOMMatrix(arrow2_transform));
    clip_mask1.addPath(arrow1, new DOMMatrix(arrow1_transform));
    const letters = [
      new Path2D("M52.186,106.029c-4.075,7.133 -9.85,11.038 -17.325,11.038c-8.325,-0 -12.738,-4.925 -12.738,-13.584c0,-10.533 6.963,-15.629 21.063,-15.629l9,0l-0,18.175Zm21.4,0.513l-0,-37.709c-0,-19.875 -10.53,-30.916 -34.65,-30.916c-10.017,-0 -21.4,2.212 -32.442,6.458l4.929,14.775c9.171,-3.054 17.833,-4.754 23.946,-4.754c11.55,-0 16.817,3.904 16.817,15.625l-0,5.096l-11.38,-0c-26.158,-0 -40.766,10.529 -40.766,30.062c-0,16.304 11.041,27.688 28.879,27.688c10.867,-0 20.55,-3.913 27,-13.421c3.4,8.833 9.854,12.233 19.879,13.079l4.583,-14.438c-4.416,-1.695 -6.795,-4.245 -6.795,-11.545"),
      new Path2D("M124.923,115.199c-12.233,-0 -20.212,-7.642 -20.212,-29.384c-0,-21.57 7.641,-30.741 20.212,-30.741c6.792,-0 12.229,2.041 18.171,6.454l9.687,-13.079c-8.495,-7.3 -17.495,-10.529 -29.216,-10.529c-25.817,-0 -41.954,19.533 -41.954,48.237c-0,28.704 16.137,46.708 42.12,46.708c11.555,0 20.896,-3.733 29.05,-10.362l-9.687,-13.758c-6.621,4.25 -11.379,6.454 -18.171,6.454"),
      new Path2D("M195.067,115.199c-12.229,-0 -20.213,-7.642 -20.213,-29.384c0,-21.57 7.642,-30.741 20.213,-30.741c6.792,-0 12.229,2.041 18.175,6.454l9.683,-13.079c-8.496,-7.3 -17.496,-10.529 -29.216,-10.529c-25.817,-0 -41.955,19.533 -41.955,48.237c0,28.704 16.138,46.708 42.125,46.708c11.55,0 20.892,-3.733 29.046,-10.362l-9.683,-13.758c-6.625,4.25 -11.383,6.454 -18.175,6.454"),
      new Path2D("M279.819,78.004l-35.33,-0c1.192,-17.834 7.642,-24.629 17.834,-24.629c12.229,-0 17.496,8.833 17.496,23.608l-0,1.021Zm-18.005,-40.088c-25.479,0 -39.912,20.725 -39.912,48.071c-0,28.367 14.946,46.879 42.8,46.879c13.587,0 24.458,-4.929 33.292,-12.058l-9.005,-12.4c-7.812,5.437 -14.437,7.983 -22.42,7.983c-11.888,0 -20.38,-5.946 -22.08,-24.291l56.392,-0c0.171,-2.375 0.508,-5.942 0.508,-8.83c0,-28.366 -14.437,-45.354 -39.575,-45.354"),
      new Path2D("M337.904,115.372c-3.738,-0 -5.096,-2.038 -5.096,-6.284l-0,-102.12l-21.742,-6.713l0,109.508c0,14.78 8.154,23.105 21.913,23.105c5.604,-0 10.87,-1.363 14.604,-3.063l-4.754,-15.283c-1.359,0.508 -3.055,0.85 -4.925,0.85"),
      new Path2D("M398.029,106.029c-4.075,7.133 -9.85,11.038 -17.325,11.038c-8.32,-0 -12.737,-4.925 -12.737,-13.584c-0,-10.533 6.967,-15.629 21.062,-15.629l9,0l0,18.175Zm21.4,0.513l0,-37.709c0,-19.875 -10.529,-30.916 -34.65,-30.916c-10.016,-0 -21.4,2.212 -32.437,6.458l4.925,14.775c9.171,-3.054 17.833,-4.754 23.946,-4.754c11.55,-0 16.816,3.904 16.816,15.625l0,5.096l-11.379,-0c-26.158,-0 -40.766,10.529 -40.766,30.062c-0,16.304 11.041,27.688 28.879,27.688c10.866,-0 20.55,-3.913 27.004,-13.421c3.396,8.833 9.85,12.233 19.875,13.079l4.583,-14.438c-4.416,-1.695 -6.796,-4.245 -6.796,-11.545"),
      new Path2D("M486.223,104.839c-5.096,7.646 -10.529,11.55 -18.512,11.55c-10.7,-0 -17.33,-7.984 -17.33,-31.079c0,-21.575 7.48,-31.084 18.688,-31.084c7.304,0 12.396,3.734 17.154,9.509l0,41.104Zm0,-104.013l0,46.775c-5.604,-5.608 -13.079,-9.683 -22.587,-9.683c-22.255,-0 -36.18,19.871 -36.18,47.558c0,28.538 11.717,47.392 34.138,47.392c11.55,-0 20.212,-5.779 25.65,-13.929l1.529,11.379l19.192,-0l-0,-122.625l-21.742,-6.867Z"),
      new Path2D("M565.754,106.029c-4.075,7.133 -9.854,11.038 -17.325,11.038c-8.325,-0 -12.738,-4.925 -12.738,-13.584c0,-10.533 6.963,-15.629 21.059,-15.629l9.004,0l-0,18.175Zm21.4,0.513l-0,-37.709c-0,-19.875 -10.529,-30.916 -34.65,-30.916c-10.017,-0 -21.4,2.212 -32.442,6.458l4.925,14.775c9.171,-3.054 17.838,-4.754 23.95,-4.754c11.55,-0 16.817,3.904 16.817,15.625l-0,5.096l-11.379,-0c-26.159,-0 -40.767,10.529 -40.767,30.062c0,16.304 11.042,27.688 28.879,27.688c10.867,-0 20.55,-3.913 27,-13.421c3.4,8.833 9.854,12.233 19.879,13.079l4.584,-14.438c-4.417,-1.695 -6.796,-4.245 -6.796,-11.545"),
      new Path2D("M638.831,116.218c-6.792,-0 -12.229,-3.054 -16.475,-9.338l0,-40.425c4.246,-6.966 10.7,-12.229 18.342,-12.229c10.871,0 16.987,8.321 16.987,31.084c0,21.737 -6.966,30.908 -18.854,30.908m8.321,-78.3c-9.679,-0 -19.192,4.246 -26.325,13.587l-1.188,-11.041l-19.025,-0l0,126.883l21.742,-2.379l0,-41.954c5.604,6.625 13.417,9.854 22.588,9.854c22.762,-0 35.5,-20.217 35.5,-47.729c-0,-28.709 -9.85,-47.221 -33.292,-47.221"),
      new Path2D("M736.319,112.143c-3.905,2.208 -7.3,3.229 -10.7,3.229c-6.625,0 -9.513,-3.567 -9.513,-12.4l0,-46.879l18.683,-0l2.209,-15.629l-20.892,-0l0,-22.417l-21.737,2.546l-0,19.871l-14.1,-0l-0,15.629l14.1,-0l-0,47.387c-0,19.196 9.337,29.217 27.004,29.388c7.983,-0 16.137,-2.213 22.587,-6.796l-7.641,-13.929Z")
    ]

    const arrow1_points = [[121.984, 10.122],
      [121.557, 29.734],
      [121.557, 48.279],
      [121.131, 65.12],
      [115.802, 79.828],
      [108.98, 98.587],
      [100.771, 109.225],
      [116.015, 89.421],
      [91.985, 113.971],
      [10.704, 117.783],
      [16.598, 122.039],
      [27.123, 124.594],
      [41.928, 127.941],
      [54.916, 127.723],
      [65.806, 126.555],
      [78.71, 121.609],
      [131.576, 19.501],
      [145.091, 31.226],
      [99.388, 30.032],
      [110.259, 19.075]];
    const arrow2_points = [[20.2, 58.516],
      [47.714, 39.094],
      [105.43, 65.265],
      [105.729, 109.304],
      [110.777, 93.479],
      [94.406, 50.094],
      [81.854, 42.782],
      [65.046, 38.962],
      [32.304, 46.602],
      [14.841, 69.522],
      [26.846, 52.714],
      [10.257, 86.112],
      [11.785, 100.737],
      [109.276, 79.427],
      [50.422, 136.753],
      [67.885, 137.102],
      [84.824, 130.903],
      [99.58, 121.299],
      [33.832, 130.729],
      [24.665, 151.466],
      [24.119, 139.46],
      [15.715, 129.163],
      [16.601, 117.968],
      [32.055, 159.109],
      [83.655, 204.586],
      [68.593, 196.182],
      [41.963, 174.026],
      [98.717, 209.934],
      [110.722, 215.173],
      [124.161, 215.645],
      [185.496, 207.996],
      [130.816, 216.728],
      [147.733, 216.619],
      [164.65, 214.108],
      [176.438, 210.943],
      [199.139, 202.539],
      [224.351, 184.422],
      [237.476, 170.342],
      [260.941, 127.095],
      [266.484, 99.383],
      [246.019, 159.923],
      [252.841, 146.706],
      [257.317, 139.032],
      [263.286, 119.42],
      [265.418, 109.828],
      [267.336, 83.608],
      [266.697, 49.501],
      [267.336, 30.316],
      [257.744, 14.968],
      [276.289, 16.886],
      [267.123, 42.04],
      [211.273, 194.456],
      [266.621, 10.504],
      [247.048, 25.005],
      [287.977, 25.987],
      [267.24, 64.515],
      [55.549, 182.708],];

    const getArrowTransform = (a, b, c, d, e, f) => [a, b, c, d, e + (cW / 2) - 157, f + (cH / 2) - 308.1];

    const removeAnimation = animation => {
      const index = this._stage3_animations.indexOf(animation);
      if (index > -1) this._stage3_animations.splice(index, 1);
    };


    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.fillStyle = bgColor;
    ctx.fillRect(0, 0, cW, cH);
    ctx.save();
    const delay1 = 100; // small delay before starting animation.
    const duration1 = anime.random(1500, 2000);
    const duration2 = anime.random(300, 600);
    const duration3 = anime.random(200, 300);
    this._stage3_animation = anime({
      delay: delay1,
      duration: Infinity,
      update: () => {
        ctx.restore();
        ctx.fillStyle = bgColor;
        ctx.fillRect(0, 0, cW, cH);
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.save();
        for (const anim of this._stage3_animations) {
          if (anim.began) {
            if (anim.to_clip) {
              ctx.save()
              ctx.setTransform(...getArrowTransform(1, 0, 0, 1, 0, 0));
              ctx.clip(clip_mask1);
              ctx.lineWidth = 10;
              ctx.strokeStyle = bgColor;
              ctx.stroke(clip_mask1)
              ctx.setTransform(1, 0, 0, 1, 0, 0);
            }
            for (const el of anim.animatables) {
              el.target.draw(ctx);
            }
          }
        }
        if (this._stage3_animations.length === 0) {
          ctx.fillStyle = bgColor;
          ctx.fillRect(0, 0, cW, cH);
          ctx.setTransform(1, 0, 0, 1, 0, 0);
          ctx.setTransform(...getArrowTransform(...arrow2_transform));
          ctx.fillStyle = green1;
          ctx.fill(arrow2);
          ctx.setTransform(...getArrowTransform(...arrow1_transform));
          ctx.fillStyle = orange1;
          ctx.fill(arrow1);
          ctx.setTransform(...getArrowTransform(1, 0, 0, 1, 0, 0));
          if (!this._stage4_timeout_handle) {
            // fadeout after a short delay
            this._stage4_timeout_handle = window.setTimeout((() => {
              this.request_show(4);
            }).bind(this), duration3)
          }
        }
        ctx.restore();
        ctx.setTransform(1, 0, 0, 1, (cW / 2) - 371, (cH / 2) - 60);
        ctx.fillStyle = fgColor;
        for (const letter of letters) {
          ctx.fill(letter)
        }
      }
    });


    const particles = [];
    const _arrow1_transform = getArrowTransform(...arrow1_transform);
    const _arrow2_transform = getArrowTransform(...arrow2_transform);
    for (const p of arrow1_points) {
      const _p = new Circle(
          anime.random(0, cW),
          anime.random(0, cH),
          anime.random(2, 8) / 2,
          1,
          null,
          null,
          orange1,
      );
      _p.dest = [p[0] + _arrow1_transform[4] + anime.random(0, 6) / 2, p[1] + _arrow1_transform[5] + anime.random(0, 6) / 2];
      particles.push(_p);
    }
    for (const p of arrow2_points) {
      const _p = new Circle(
          anime.random(0, cW),
          anime.random(0, cH),
          anime.random(2, 8) / 2,
          1,
          null,
          null,
          green1
      );
      _p.dest = [p[0] + _arrow2_transform[4] + anime.random(0, 6) / 2, p[1] + _arrow2_transform[5] + anime.random(0, 6) / 2];
      particles.push(_p);
    }


    const particlesAnimation1 = anime({
      targets: particles,
      x: p => {
        return p.dest[0]
      },
      y: p => {
        return p.dest[1]
      },
      r: anime.random(10, 18) / 2,
      easing: "easeOutQuad",
      delay: delay1,
      duration: duration1,
      complete: removeAnimation
    });
    this._stage3_animations.push(particlesAnimation1);
    const particlesAnimation2 = anime({
      targets: particles,
      r: 23,
      easing: "easeInOutExpo",
      delay: duration1 + delay1,
      duration: duration2,
      complete: removeAnimation
    });
    particlesAnimation2.to_clip = true;
    this._stage3_animations.push(particlesAnimation2);
  }
}


class Circle {
  constructor(x, y, r, opacity, stroke_color, stroke_width = 0, fill_color) {
    this.x = x;
    this.y = y;
    this.r = r;
    this.opacity = opacity;
    this.stroke_color = stroke_color;
    this.stroke_width = stroke_width;
    this.fill_color = fill_color;
  }

  draw(ctx) {
    ctx.globalAlpha = this.opacity || 1;
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.r, 0, 2 * Math.PI, false);
    if (this.fill_color) {
      ctx.fillStyle = this.fill_color;
      ctx.fill();
    }
    if (this.stroke_width) {
      ctx.strokeStyle = this.stroke_color;
      ctx.lineWidth = this.stroke_width;
      ctx.stroke();
    }
    ctx.closePath();
    ctx.globalAlpha = 1;
  }
}


// iife
if (!window.loading_overlay) {
  window.loading_overlay = new LoadingOverlay();
}
